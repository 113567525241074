<template>
  <div>
    <div>
      <div class="url-row">{{ url }}</div>
      <iframe  allowfullscreen="true" class="main-frame" ref="frame" :src="url" frameborder="0"></iframe>
    </div>
    <div>
      <!--- <div class="item-list">
         <ul>
           <li v-for="message in messages">
             <h5>{{ message.action }} {{ message.date }}</h5>
             <div>{{ message.source }}</div>
           </li>
         </ul>
       </div>
       --->

    </div>
  </div>
</template>

<script>



export default {
  name: 'Page',
  data: () => ({
    url: "https://partner.test.k8s-hz.atlas-iac.com/",
    messages: [],

  }),
  methods: {

    loginPopup() {
      this.$store.dispatch("ui/login", true)
    },
    async getUrl() {
      try {
        const {data: {url}} = await this.$http.get("/url")
        this.url = url;
      } catch (err) {
        console.error(err)
      }
    }
  },
  created() {
   this.$eventBus.on("authStateChange", this.getUrl.bind(this))

    window.addEventListener("message", ({data: {action, payload, source}}) => {
      this.messages.push({
        "action": action,
        // "data": JSON.stringify(payload || "{}"),
        "source": source || "",
        "time": new Date()
      })
      switch (action) {
        case "ui:content_height":

          break
      }


      this.messages = this.messages.slice(-30)
    })

  },
  async mounted() {
   await this.getUrl()
    // this.$store.dispatch("account/login", {visible: true})
    // debugger
  }
}
</script>
0
<
!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-frame {
  width: 100%;
  height: calc(100vh - 100px);
}

.url-row {
  padding: 10px;
  border-radius: 6px;
  text-indent: 20px;
  background: #dbdbdb;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.item-list {
  height: 300px;
  overflow-y: scroll;
  background: #fff;
}

.item-list li {
  display: block;
}

a {
  color: #42b983;
}
</style>
