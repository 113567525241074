<template>
    <div class="container">
        <a-row type="flex" justify="start">
            <a-col :span="12">
                <a-space>
                    <span class="logo">ATLAS PARTNER API</span>
                    <a-form>
                        <a-select :value="env" default-value="test" style="width: 120px" @change="handleChange">
                            <a-select-option value="test">
                                Test
                            </a-select-option>
                            <a-select-option value="qa">
                                QA
                            </a-select-option>
                            <a-select-option value="preprod">
                                Preprod
                            </a-select-option>
                        </a-select>
                    </a-form>
                </a-space>
            </a-col>
            <a-col :span="12">
                <a-space class="right">
                    <div v-if="isAuthenticated && Object.keys(accountData).length > 0">
                        <a-space>
                            <span class="user-data">ID:{{ accountData.id }} ({{ accountData.username }})</span>
                            <a-tag class="balance-data">{{accountData.currency.code}} {{ balance }}</a-tag>
                            <a-button @click="logout" type="danger">Log out</a-button>
                        </a-space>
                    </div>
                    <div v-else>
                        <a-space class="right">
                            <a-button @click="loginPopup" type="primary">Login</a-button>
                            <a-button @click="registerPopup" type="success">Register</a-button>
                        </a-space>
                    </div>
                </a-space>
            </a-col>
        </a-row>
    </div>
</template>


<script>
    import Login from "./Login";
    import {mapGetters, mapState} from "vuex";

    export default {
        name: "Header",
        components: {
            Login
        },
        computed: {
            ...mapState("env", {
                env: state => state.env
            }),
            ...mapGetters("account", ["authRequired", "isAuthenticated", "balance", "accountData"])
        },
        methods: {
            logout() {
                this.$store.dispatch("account/logout")
                this.$eventBus.emit("authStateChange", 1)
            },
            async handleChange(env) {
                await this.$store.dispatch("env/setEnv", env)
                this.$eventBus.emit("authStateChange", 1)
                this.logout()
            },
            loginPopup() {
                this.$store.dispatch("ui/login", true)
            },
            registerPopup() {
                this.$store.dispatch("ui/register", true)
            }
        }
        ,
    }
</script>

<style scoped>
    .logo {
        color: white;
        font-size: 21px;
        margin-right: 50px;
    }

    .balance-data {
        padding: 5px;
    }

    .right {
        float: right;
    }

    .user-data {
        text-decoration: underline;
        color: white;
        font-size: 14px;
    }

    h1, h3 {
        color: white;
    }

    .container {
        border-bottom: 2px solid white;
    }

</style>
