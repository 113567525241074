import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
// Vue.config.productionTip = false;
import VueAxios from 'vue-plugin-axios'
import axios from 'axios'
import createStore from './store/store'
import VueEventBus from "vue-plugin-event-bus";
import VueNativeSock from 'vue-native-websocket'
import SockJS from 'sockjs-client'
Vue.use(Vuex);
Vue.use(VueEventBus);
Vue.use(Antd);
Vue.config.productionTip = false;

const store = createStore();

const baseURL = "/"

Vue.use(VueAxios, {
    axios,
    config: { // axios instance config
        baseURL, // api URL
        headers: {
            "content-type": "application/json",
        }, // default headers
    },
});


Vue.use(VueNativeSock, "", {
    connectManually: true,
    store: store,
    format: "json"
});


new Vue({
    store,
    created() {
        this.$http.interceptors.request.use((config) => {
            const token = this.$store.state.account.token;
            const env = this.$store.state.env.env;
            config.headers["X-ENV"] = env;
            if (token) {
                config.headers["X-AUTH"] = token;
            }
            return config;
        })
        setTimeout(() => {
            this.$eventBus.emit("authStateChange", 1)
        })


        this.$eventBus.on("authStateChange", () => {
            if (store.state.account.token) {

                this.$http.get("/balance").then(async (res) => {
                    await store.dispatch("account/setBalance", res.data.balance)
                });

                if (process.env.NODE_ENV == "development") {
                    const sock = new SockJS(`http://localhost:8080/ws?token=${store.state.account.token}`)
                    this.$connect(`http://localhost:8080/ws?token=${store.state.account.token}`,{
                            WebSocket: sock,
                    })
                    debugger

                    // this.$connect(`ws://${process.env.VUE_APP_BASE_URL}/ws?token=${store.state.account.token}`);
                } else {
                    // this.$connect(`wss://${window.location.host}/ws?token=${store.state.account.token}`);

                }

            } else {
                this.$disconnect()
            }
        })
    },
    render: h => h(App),
}).$mount('#app')
