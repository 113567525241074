<template>
    <a-layout id="components" class="layout">
        <a-layout-header>
            <Header/>
        </a-layout-header>
        <Page/>
        <Login/>
        <Register/>
    </a-layout>
</template>

<script>
    import Page from './components/Page.vue'
    import Header from './components/Header.vue'
    import Login from './components/Login.vue'
    import Register from './components/Register.vue'

    export default {
        name: 'App',
        components: {
            Page,
            Header,
            Login,
            Register,
        }
    }
</script>

<style>
</style>
