<template>
    <div>
        <a-modal :footer="null" :visible="visible" title="Login" @cancel="close">
            <a-form
                    id="components-form-demo-normal-login"
                    :form="formData"
                    class="login-form"
                    @submit="handleSubmit"
            >
                <a-form-item>
                    <a-input v-decorator="['username', { rules: [{ required: true, message: 'Please input your username!' }] },]" placeholder="Username">
                        <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)"/>
                    </a-input>
                </a-form-item>
                <a-form-item>
                    <a-input v-decorator="[ 'password',  { rules: [{ required: true, message: 'Please input your Password!' }] }, ]" type="password" placeholder="Password">
                        <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)"/>
                    </a-input>
                </a-form-item>
                <a-form-item>
                    <a-button :disabled="!errorStatus" type="primary" html-type="submit" class="login-form-button">
                        Log in
                    </a-button>
                </a-form-item>
                <a-alert v-if="this.errors.credentials"
                         message="Invalid username or password"
                         description="Username and password combination is invalid please try again or contact support"
                         type="error"
                         closable
                         show-icon
                         @close="errorClose"
                />
            </a-form>
            <template slot="footer">
            </template>
        </a-modal>
    </div>
</template>


<style>
    #components-form-demo-normal-login .login-form {
        max-width: 300px;
    }

    #components-form-demo-normal-login .login-form-forgot {
        float: right;
    }

    #components-form-demo-normal-login .login-form-button {
        width: 100%;
    }
</style>

<script>

    import {mapState} from 'vuex';

    export default {
        computed: {
            ...mapState({visible: state => state.ui.modal?.login}),
            errorStatus() {
                return !this.errors.credentials && !this.errors.validation
            }
        },

        beforeCreate() {
            this.formData = this.$form.createForm(this, {name: 'normal_login'});
        },
        data() {
            return {
                errors: {
                    credentials: false,
                    validation: false,
                },
                user: {
                    username: '',
                    password: '',
                },
            }
        },
        methods: {
            async handleSubmit(e) {

                e.preventDefault();
                this.formData.validateFields(async (err, values) => {
                    if (err) {
                        this.errors.validation = true
                        return
                    }
                    try {
                        const {data: result} = await this.$http.post("/login", values)
                        await this.$store.dispatch("account/setToken", result.token)
                        await this.$store.dispatch("account/setData", result.client)
                        this.$eventBus.emit("authStateChange",1)
                        this.close()
                    } catch (err) {
                        this.errors.credentials = true;
                    }
                });


            },
            errorClose() {
                this.errors.credentials = false;
            },
            close() {
                this.$store.dispatch("ui/login", false)
            }
        },
    };
</script>


