<template>
    <div>
        <a-modal :footer="null" :visible="visible" title="Registration" @cancel="close">
            <a-form
                    id="components-form-demo-normal-login"
                    :form="formData"
                    class="register-form"
                    @submit="handleSubmit"
            >
                <a-form-item>
                    <a-input v-decorator="['username', { rules: [{ required: true, message: 'Please input your username!' }] },]" placeholder="Username">
                        <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)"/>
                    </a-input>
                </a-form-item>
                <a-form-item>
                    <a-input v-decorator="[ 'password',  { rules: [{ required: true, message: 'Please input your Password!' }] }, ]" type="password" placeholder="Password">
                        <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)"/>
                    </a-input>
                </a-form-item>
                <a-form-item>
                    <a-input v-decorator="[ 'password2',  { rules: [
                            { required: true, message: 'Please input your Password!' },
                            { validator: compareToFirstPassword},
                        ] }, ]" type="password" placeholder="Confirm Password">
                        <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)"/>
                    </a-input>
                </a-form-item>
                <a-form-item>
                    <a-select v-decorator="['currency', { rules: [{ required: true, message: 'Please input your currency!' }] },]"
                              show-search
                              placeholder="Currency"
                              option-filter-prop="children"
                              style="width: 200px"
                              :filter-option="filterOption"
                    >
                        <a-select-option value="USD">
                            USD
                        </a-select-option>
                        <a-select-option value="EUR">
                            EUR
                        </a-select-option>
                        <a-select-option value="UAH">
                            UAH
                        </a-select-option>
                        <a-select-option value="RUR">
                            RUR
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item>
                    <a-space>
                        <a-button :disabled="!errorStatus" type="primary" html-type="submit" class="login-form-button">
                            Register
                        </a-button>
                        <a-button type="default" html-type="button" class="login-form-button">
                            Cancel
                        </a-button>
                    </a-space>
                </a-form-item>
                <a-alert v-if="this.errors"
                         message="Can't register user"
                         type="error"
                         closable
                         show-icon
                         @close="errorClose"
                />
            </a-form>
            <template slot="footer">
            </template>
        </a-modal>
    </div>
</template>


<style>
    #components-form-demo-normal-login .login-form {
        max-width: 300px;
    }

    #components-form-demo-normal-login .login-form-forgot {
        float: right;
    }

    #components-form-demo-normal-login .login-form-button {
        width: 100%;
    }
</style>

<script>

    import {mapState} from 'vuex';

    export default {
        computed: {
            ...mapState({visible: state => state.ui.modal?.register}),
            errorStatus() {
                return !this.errors.credentials && !this.errors.validation
            }
        },

        beforeCreate() {
            this.formData = this.$form.createForm(this, {name: 'normal_register'});
        },

        data() {
            return {
                errors: false,
                user: {
                    username: '',
                    password: '',
                    password2: '',
                },
            }
        },
        methods: {
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },

            compareToFirstPassword(rule, value, callback) {
                const form = this.formData;

                if (value && value !== form.getFieldValue('password')) {
                    callback('Two passwords that you enter is inconsistent!');
                } else {
                    callback();
                }
            },
            handleSubmit(e) {
                e.preventDefault();
                this.formData.validateFields(async (err, values) => {
                    if (err) {
                        console.log(err);
                        this.errors = true;
                        return
                    }
                    try {
                        const {data: result} = await this.$http.post("/register", values);
                        await this.$store.dispatch("account/setToken", result.token);
                        await this.$store.dispatch("account/setData", result.client);
                        this.$eventBus.emit("authStateChange",1)
                        this.close()
                    } catch (err) {
                        this.errors = true
                    }
                });


            },
            errorClose() {
                this.errors = false
            },
            close() {
                this.$store.dispatch("ui/register", false)
            }
        },
    };
</script>


