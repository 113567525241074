import Vuex from "vuex";
import VuexPersistence from "vuex-persist";


const actions = {
    SET_TOKEN: "SET_TOKEN",
    SET_LOGIN_MODAL: "SET_LOGIN_MODAL",
    SET_REGISTER_MODAL: "SET_REGISTER_MODAL",
    SET_BALANCE: "SET_BALANCE",
    SET_LOGOUT: "SET_LOGOUT",
    SET_DATA: "SET_DATA",
    SET_ENV: "SET_ENV",
    SOCKET_ONOPEN: "SOCKET_ONOPEN",
    SOCKET_ONCLOSE: "SOCKET_ONCLOSE",
    SOCKET_ONERROR: "SOCKET_ONERROR",
    SOCKET_ONMESSAGE: "SOCKET_ONMESSAGE",
    SOCKET_RECONNECT: "SOCKET_RECONNECT",
    SOCKET_RECONNECT_ERROR: "SOCKET_RECONNECT_ERROR"
}


const accountInit = {
    balance: 0,
    token: null,
    visible: {
        login: false
    },
    data: {},
};
const uiInit = {
    modal: {
        login: false,
        register: false
    }
}

const envInit = {
    env: "test",
};

const WsMessageTypes = {
    WS_BALANCE: "balance"
}

export default function () {
    return new Vuex.Store({

        mutations: {
            [actions.SOCKET_ONOPEN](state, message) {

            },
            [actions.SOCKET_ONMESSAGE](state, message) {
                if ("type" in message) {
                    switch (message.type) {
                        case WsMessageTypes.WS_BALANCE:
                            this.dispatch("account/setBalance", message.value);
                            break;
                        default:
                            console.error(message.type + " is unknown")

                    }
                }
            }
        },
        modules: {
            env: {
                namespaced: true,
                state: () => envInit,
                actions: {
                    setEnv({commit}, value) {
                        commit(actions.SET_ENV, value)
                    },
                },
                mutations: {
                    [actions.SET_ENV](state, value) {
                        state.env = value
                    },
                }
            },
            ui: {
                state: () => uiInit,
                namespaced: true,
                actions: {
                    login({commit}, status) {
                        commit(actions.SET_LOGIN_MODAL, status)
                    },
                    register({commit}, status) {
                        commit(actions.SET_REGISTER_MODAL, status)
                    },
                },
                mutations: {
                    [actions.SET_LOGIN_MODAL](state, status) {
                        state.modal = {...state.modal, login: status}
                    },
                    [actions.SET_REGISTER_MODAL](state, status) {
                        state.modal = {...state.modal, register: status}
                    }
                }
            },
            account: {
                namespaced: true,
                // содержимое модуля
                state: () => accountInit,
                getters: {
                    accountData: state => state.data,
                    balance: state => state.balance,
                    isAuthenticated: state => Boolean(state.token),
                    authRequired: state => !Boolean(state.token),
                    Token: state => state.token,
                },
                actions: {
                    setBalance({commit}, balance) {
                        commit(actions.SET_BALANCE, balance)
                    },
                    setData({commit}, data) {
                        commit(actions.SET_DATA, data)
                    },
                    logout({commit}) {
                        commit(actions.SET_LOGOUT)
                    },
                    setToken({commit}, payload) {
                        commit(actions.SET_TOKEN, {token: payload})
                    }
                },
                mutations: {
                    [actions.SET_LOGOUT](state) {
                        Object.assign(state, accountInit)
                    },
                    [actions.SET_DATA](state, data) {
                        Object.assign(state, {data:data})
                    },
                    [actions.SET_BALANCE](state, balance) {
                        state.balance = balance
                    },
                    [actions.SET_TOKEN](state, payload) {
                        state.token = payload.token
                    },

                },
            }
        },
        plugins: [new VuexPersistence().plugin]
    });
}
